import React from 'react'
import { Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'
import { useMdxEntries } from 'src/hooks/use-mdx-entries'
import { JobAds } from 'src/components/jobs'
import { RawNewsPage } from 'src/components/news'
import { SeeMore } from 'src/components/links'
import Image from 'src/components/ghp-photo';

function IndexPage() {
  const { edges: recent } = useMdxEntries()
  return (
    <BasicLayout>
      <Image/>
      <p>Hi! I'm <b>Gus Hahn-Powell</b>. <Link to={`about`}>You can find out more about me here</Link>.</p>     
      <h1>News</h1>
        <RawNewsPage maxYears={1}/>  
        <SeeMore page={"/news"}/>
      <h1>Hiring</h1>
        <JobAds/>
    </BasicLayout>
  )
}

export default IndexPage
