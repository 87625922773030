import React from 'react'
import { Link } from 'gatsby'

function SeeMore({ page }) {
  return (
    <p><Link to={page}>see more</Link></p>
  )
}

export {
  SeeMore
}